<template>
  <div class="map-container" style="position: relative">
    <StopsComponent
      v-if="
        trackingData.toAddress &&
        isBelowMobileBreakpoint &&
        !configGetter(this.$rttI18n.tenantId, this.getThemeId).disableOverview
      "
      :top="true"
      :stops="trackingData.status.remainingStops"
    />
    <PostCodeInputComponent />
    <v-card v-if="!trackingData.toAddress">
      <img
        class="staticmap_fallback v-col-12 pa-0 d-block"
        alt="Static Map"
        src="/staticmap_550x450.jpg"
      />
    </v-card>
    <AwsMap
      v-if="trackingData.toAddress"
      v-on:initialized="awsMapInitialized"
      class="map-wrapper v-col-12 pa-0"
      :class="wrapperClass"
      :center="position"
      :zoom="15"
      :options="mapOptions"
    />
    <StopsComponent
      v-if="
        trackingData.toAddress &&
        (!isBelowMobileBreakpoint ||
          configGetter(this.$rttI18n.tenantId, this.getThemeId).disableOverview)
      "
      :top="false"
      :stops="trackingData.status.remainingStops"
    />
    <p
      v-if="showPositionExplanation"
      class="text-footnote ml-1 mt-2"
      :style="`${
        isBelowMobileBreakpoint
          ? ''
          : 'width: 50%; padding-right: calc(66px + 0.2em);'
      }`"
    >
      {{
        $t('stops.positionExplanation', {
          stops: trackingData.maxStopsToShowDriverLocation
        })
      }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PostCodeInputComponent from '../PostCodeInputComponent';
import StopsComponent from '../StopsComponent';
import {
  TOGGLE_DIALOG_EVENT,
  TRACKING_DELIVERY_STATE_NEAR,
  TRACKING_STATE_DETAIL_PARCEL_SHOP
} from '@/store/constants';
import AwsMap from '@/components/map/AwsMap';
import { LngLat, LngLatBounds, Marker } from 'maplibre-gl';
import { sendAnalytics } from '@/services/analytics';
import isEqual from 'lodash/isEqual';
import publicPath from '@/util/publicPath';
import { configGetter } from '@/config/configGetter';
import { dialogs } from '@/components/dialogs/dialogs.js';
import { toRaw } from 'vue';

export default {
  name: 'AwsMapComponent',
  components: {
    AwsMap,
    PostCodeInputComponent,
    StopsComponent
  },
  props: {
    showLiveMap: {
      type: Boolean,
      default: false
    },
    trackingId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      map: null,
      dotAnimationInterval: null,
      previousRoutePath: null,
      iconDotMarker: null,
      TRACKING_DELIVERY_STATE_NEAR
    };
  },
  computed: {
    ...mapState(['isBelowMobileBreakpoint', 'trackingData']),
    ...mapGetters([
      'getDeliveryStatusDetail',
      'getTrackingState',
      'showPositionExplanation',
      'isServiceTypePickup',
      'getThemeId'
    ]),
    isParcelShopDelivery() {
      return this.getDeliveryStatusDetail === TRACKING_STATE_DETAIL_PARCEL_SHOP;
    },
    mapOptions() {
      return {
        maxZoom: 16,
        interactive: this.showLiveMap,
        maxBounds: LngLatBounds.fromLngLat(
          new LngLat(this.position.lng, this.position.lat),
          100000
        )
      };
    },
    position() {
      return (
        this.trackingData.toAddress?.coordinates ||
        this.deliveredToParcelShopLocation ||
        this.trackingData.status.position ||
        this.trackingData.status.address?.coordinates || { lat: 0, lng: 0 }
      );
    },
    wrapperClass() {
      return configGetter(this.$rttI18n.tenantId, this.getThemeId)
        .disableOverview
        ? 'overview-disabled'
        : '';
    },
    deliveredToParcelShop() {
      if (!this.trackingData.status.address) return;

      return this.trackingData.nearParcelShops?.find(
        it => it.name === this.trackingData.status.address.name
      );
    },
    deliveredToParcelShopLocation() {
      const address = this.deliveredToParcelShop?.address;
      return (
        address && {
          lng: address.longitude,
          lat: address.latitude
        }
      );
    },
    showPositionExplanation() {
      return (
        this.getTrackingState === TRACKING_DELIVERY_STATE_NEAR &&
        this.trackingData.toAddress
      );
    }
  },
  methods: {
    configGetter,
    ...mapActions(['setMessage']),
    marker({ element, position }) {
      return new Marker({ element }).setLngLat(position).addTo(this.map);
    },
    createMapMarkerElement(icon) {
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = `url(${icon.image})`;
      el.style.backgroundSize = 'contain';
      el.style.width = icon.width + 'px';
      el.style.height = icon.height + 'px';
      el.style.zIndex = icon.zIndex || '1';

      return el;
    },
    createMapMarker(position, icon) {
      return this.marker({
        element: this.createMapMarkerElement(icon),
        position
      });
    },
    dialogHandler(dialog, payload) {
      this.$mitt.emit(TOGGLE_DIALOG_EVENT, { dialog, payload });
    },
    createParcelShopMarker(parcelShop, icon) {
      let el = this.createMapMarkerElement(icon);
      el.style.cursor = 'pointer';
      el.onclick = () => {
        sendAnalytics(this.trackingData, 'map', 'parcel_shop_marker_clicked', {
          parcelShopId: parcelShop.parcelShopId,
          distance: parcelShop.distance,
          type: parcelShop.type,
          name: parcelShop.name
        });
        this.dialogHandler(dialogs.PARCEL_SHOP, parcelShop);
      };
      return this.marker({
        element: el,
        position: new LngLat(
          parcelShop.address.longitude,
          parcelShop.address.latitude
        )
      });
    },
    parcelShopToLngLat(parcelShop) {
      return new LngLat(
        parcelShop.address.longitude,
        parcelShop.address.latitude
      );
    },
    animateRoute(geoJson) {
      const iconDot = publicPath(
        `/themes/${this.getThemeId}/map/icon_route_dot.svg`
      );
      const routePath = this.getRouteCoordinates(geoJson);

      if (!this.previousRoutePath) {
        this.previousRoutePath = routePath;
      } else {
        if (isEqual(routePath, this.previousRoutePath)) {
          return;
        }
        if (this.dotAnimationInterval) {
          clearInterval(this.dotAnimationInterval);
        }
        if (this.iconDotMarker) {
          this.iconDotMarker.remove();
        }
      }

      const routeLegsLength = routePath?.length - 2;
      if (routeLegsLength > 1 && this.trackingData.status.remainingStops > 0) {
        const stopFactor = Math.round(
          routeLegsLength / this.trackingData.status.remainingStops
        );
        let intervalIteration = 1;

        this.iconDotMarker = this.createMapMarker(routePath[1], {
          image: iconDot,
          width: 8,
          height: 8,
          zIndex: '0'
        });

        this.dotAnimationInterval = setInterval(() => {
          const leg = stopFactor * intervalIteration;
          if (leg < routeLegsLength) {
            const routeLeg = routePath[leg];
            this.iconDotMarker.setLngLat(routeLeg);
            intervalIteration++;
          } else {
            intervalIteration = 1;
          }
        }, 1000);
      }
    },
    getRouteCoordinates(routeGeoJson) {
      return routeGeoJson.geometry?.coordinates;
    },
    getRoute() {
      return this.trackingData.routeGeoJson;
    },
    removeRoute() {
      if (this.map) {
        this.map.removeLayer('route');
        this.map.removeSource('route');
      }
    },
    executeOnMap(callback) {
      if (!this.map) {
        // eslint-disable-next-line no-console
        console.error('Map not initialized yet.');
      } else if (this.map._loaded) {
        callback();
      } else {
        this.map.on('load', callback);
      }
    },
    showRoute() {
      const routeColor = {
        bvb: 'rgba(0,0,0,0.35)',
        juskys: 'rgba(147, 192, 32, 0.5)',
        skandic: 'rgba(205, 47, 42, 0.5)',
        hmsk: 'rgba(77, 169, 225, 0.5)',
        theissen: 'rgba(83, 188, 133, 0.5)',
        rotWeissEssen: 'rgba(227, 6, 27, 0.5)',
        gentor: 'rgba(227, 6, 25, 0.5)',
        'royalmail-uk': 'rgba(230, 51, 56, 1)'
      };

      this.showParcelMarker();
      const geoJson = this.getRoute();

      if (geoJson?.bbox) {
        this.map.fitBounds(this.getBounds().extend(toRaw(geoJson.bbox)), {
          padding: 60,
          animate: false
        });
      }

      const source = this.map.getSource('route');
      if (source) {
        source.setData(geoJson);
      } else {
        this.executeOnMap(() => {
          this.map.addSource('route', { type: 'geojson', data: geoJson });
          this.map.addLayer({
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': routeColor[this.getThemeId] ?? 'rgba(6,26,177,0.4)',
              'line-width': 5
            }
          });
        });
      }

      this.animateRoute(geoJson);
    },
    getBounds() {
      let bounds = LngLatBounds.fromLngLat(
        LngLat.convert(this.trackingData.toAddress?.coordinates),
        0
      );

      if (this.getParcelLocation())
        bounds = bounds.extend(LngLat.convert(this.getParcelLocation()));

      const shop = this.trackingData.nearParcelShops?.[0];
      if (shop && shop.distance <= 2) {
        bounds = bounds.extend(this.parcelShopToLngLat(shop));
      }

      return bounds;
    },
    fitToBounds() {
      this.map.fitBounds(this.getBounds().toArray(), {
        padding: 60,
        animate: false
      });
    },
    showParcelMarker() {
      const mapMarkerParcel = publicPath(
        `/themes/${this.getThemeId}/map/icon_parcel_on_delivery.svg`
      );
      const mapMarkerPickup = publicPath(
        `/themes/${this.getThemeId}/map/icon_pickup.svg`
      );

      if (this.parcelPositionMarker) this.parcelPositionMarker.remove();
      this.parcelPositionMarker = this.createMapMarker(
        this.trackingData.status.position,
        {
          height: 60,
          image: this.isServiceTypePickup ? mapMarkerPickup : mapMarkerParcel,
          width: 60
        }
      );
    },
    showParcelShops() {
      if (this.parcelShopMarkers) {
        this.parcelShopMarkers?.forEach(m => m.remove());
      }

      this.parcelShopMarkers = this.trackingData.nearParcelShops?.map(
        parcelShop => {
          return this.createParcelShopMarker(
            parcelShop,
            this.deliveredToParcelShop?.name === parcelShop.name
              ? {
                  height: 35,
                  image: publicPath(
                    `/themes/${this.getThemeId}/map/icon_gls_parcelshop1.svg`
                  ),
                  width: 35
                }
              : {
                  height: 30,
                  image: publicPath(
                    `/themes/${this.getThemeId}/map/icon_gls_parcelshop2.svg`
                  ),
                  width: 30
                }
          );
        }
      );
    },
    showAddressLocation() {
      const mapMarkerDestination = publicPath(
        `/themes/${this.getThemeId}/map/icon_destination.svg`
      );

      if (this.addressMarker) {
        this.addressMarker.remove();
      }

      this.addressMarker = this.createMapMarker(
        this.trackingData.toAddress?.coordinates,
        {
          height: 60,
          image: mapMarkerDestination,
          width: 60,
          zIndex: '10'
        }
      );
    },
    showParcelShopMarker() {
      const mapMarkerParcelShop = publicPath(
        `/themes/${this.getThemeId}/map/icon_gls_parcelshop.svg`
      );

      if (this.statusAddressMarker) this.statusAddressMarker.remove();
      this.statusAddressMarker = this.createMapMarker(
        this.getParcelShopLocation(),
        {
          height: 60,
          image: mapMarkerParcelShop,
          width: 60
        }
      );
    },
    getParcelLocation() {
      return (
        this.trackingData.status.address?.coordinates ||
        this.trackingData.status.position ||
        this.deliveredToParcelShopLocation
      );
    },
    getParcelShopLocation() {
      return (
        this.trackingData.status.address?.coordinates ||
        this.deliveredToParcelShopLocation
      );
    },
    mapInit() {
      this.map.on('load', () => {
        this.map.removeLayer('Spot elevation'); // do not show elevation in meters on map
      });
      this.showAddressLocation();
      this.showParcelShops();
      this.fitToBounds();

      if (this.getRoute()) {
        this.showRoute();
      } else if (
        this.isParcelShopDelivery &&
        !!this.getParcelShopLocation() &&
        !this.deliveredToParcelShopLocation
      ) {
        this.showParcelShopMarker();
      }
    },
    awsMapInitialized(map) {
      this.map = map;
      this.mapInit();
    }
  },
  watch: {
    getThemeId() {
      this.mapInit();
    },
    'trackingData.status.remainingStops'() {
      if (!this.getRoute()) {
        this.removeRoute();
      }
    },
    'trackingData.status.position'(newPosition, oldPosition) {
      const distanceCheck =
        !oldPosition || LngLat.convert(newPosition).distanceTo(oldPosition) > 0;
      if (
        this.map &&
        this.getRoute() &&
        this.getParcelLocation() &&
        distanceCheck
      ) {
        this.showRoute();
      }
    },
    'trackingData.toAddress.coordinates'(newPosition, oldPosition) {
      const distanceCheck =
        !oldPosition || LngLat.convert(newPosition).distanceTo(oldPosition) > 0;
      if (this.map && distanceCheck) {
        this.showAddressLocation();
      }
    },
    'trackingData.status.address'(address) {
      if (this.map && !!this.getParcelShopLocation()) {
        this.fitToBounds();
        if (!this.deliveredToParcelShopLocation) {
          this.showParcelShopMarker();
        }
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.dotAnimationInterval);
  }
};
</script>

<style scoped>
.map-container {
  margin-bottom: 8px;
}

@media (min-width: 960px) {
  .map-container:has(> :nth-child(1)) {
    margin-bottom: 24px;
  }

  .map-container:has(> :nth-child(2)) {
    margin-bottom: 80px;
  }
}

.map-wrapper {
  height: 40vh;
  position: relative;
  border-radius: var(--border-radius-root);
  overflow: hidden;
}

.staticmap_fallback {
  min-height: 300px;
}

@media (min-width: 1264px) {
  .map-wrapper {
    height: 60vh;
  }
}

@media (min-width: 960px) {
  .map-wrapper {
    height: 60vh;
  }
}

.overview-disabled {
  height: calc(100vh - 80px) !important; /* see StopsComponent height: 80px; */
}
</style>
