import HttpRequestBuilder from '../services/http/httpRequest/HttpRequestBuilder';
import {
  ACTION_TYPE_ERROR,
  ADD_RATING_PATH,
  CUSTOMIZE_DELIVERY_PATH,
  NOTIFICATION_SUBSCRIPTION_PATH,
  PROCESS_ADD_CUSTOMIZED_DELIVERY,
  PROCESS_ADD_NOTIFICATION_SUBSCRIPTION,
  PROCESS_ADD_RATING,
  PROCESS_LOAD_TRACKING_DATA,
  PROCESS_LOAD_TRIGGER_TRACKING_URL,
  TIME_FRAME_FAILURE_PATH,
  TRACKING_DATA_BY_ID_AND_ZIPCODE_PATH,
  TRACKING_DATA_BY_ID_PATH
} from './constants';
import { subscribe } from '@/services/pushNotification';
import { convertJsonFetchPromise } from '@/services/fetch';
import { inputToTenant } from '@/config/countriesAndLocales.js';

const rttBaseParams = (builder, payload) => {
  const baseHostname = new URL(
    parent !== window ? document.referrer : document.location.href
  ).hostname;

  return builder
    .setHeader('Content-Type', 'application/json')
    .setHeader('x-original-hostname', baseHostname)
    .setHeader('x-original-utm-source', payload.utmSource || null)
    .setHeader(
      'x-original-referrer-hostname',
      document.referrer ? new URL(document.referrer).hostname : ''
    )
    .pathParam('tenant', inputToTenant(payload.tenantId))
    .pathParam('postcode', payload.postcode)
    .pathParam('trackingId', payload.trackingId);
};

export function createActions(httpService) {
  return {
    async loadTrackingDataById({ commit, dispatch }, payload) {
      const request = rttBaseParams(
        HttpRequestBuilder.get(TRACKING_DATA_BY_ID_PATH),
        payload
      )
        .onInit(() => {
          commit('updateMessage', {});
          dispatch('startProcess', PROCESS_LOAD_TRACKING_DATA);
        })
        .onSuccess((request, response) => {
          dispatch('stopProcess', PROCESS_LOAD_TRACKING_DATA);
          commit('updateTrackingData', response.getBody());
        })
        .onError((request, response) => {
          dispatch('stopProcess', PROCESS_LOAD_TRACKING_DATA);
          commit('updateFailure', {
            PROCESS_LOAD_TRACKING_DATA,
            modal: false,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .onFailure((request, response) => {
          dispatch('stopProcess', PROCESS_LOAD_TRACKING_DATA);
          commit('updateFailure', {
            PROCESS_LOAD_TRACKING_DATA,
            modal: false,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .onNotFound((request, response) => {
          dispatch('stopProcess', PROCESS_LOAD_TRACKING_DATA);
          commit('updateFailure', {
            PROCESS_LOAD_TRACKING_DATA,
            modal: false,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .build();

      await httpService.executeRequest(request);
    },
    async loadAdditionalTrackingData({ commit, dispatch }, payload) {
      const request = rttBaseParams(
        HttpRequestBuilder.get(TRACKING_DATA_BY_ID_AND_ZIPCODE_PATH),
        payload
      )
        .onInit(() => {
          commit('updateMessage', {});
          dispatch('startProcess', PROCESS_LOAD_TRACKING_DATA);
        })
        .onSuccess((request, response) => {
          dispatch('stopProcess', PROCESS_LOAD_TRACKING_DATA);
          commit('updatePersonalTrackingData', response.getBody());
        })
        .onError((request, response) => {
          dispatch('stopProcess', PROCESS_LOAD_TRACKING_DATA);
          commit('updateFailure', {
            PROCESS_LOAD_TRACKING_DATA,
            modal: false,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .onFailure((request, response) => {
          dispatch('stopProcess', PROCESS_LOAD_TRACKING_DATA);
          commit('updateFailure', {
            PROCESS_LOAD_TRACKING_DATA,
            modal: false,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .onNotFound((request, response) => {
          dispatch('stopProcess', PROCESS_LOAD_TRACKING_DATA);
          commit('updateFailure', {
            PROCESS_LOAD_TRACKING_DATA,
            modal: false,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .onUnauthorized((request, response) => {
          dispatch('stopProcess', PROCESS_LOAD_TRACKING_DATA);
          commit('updateFailure', {
            PROCESS_LOAD_TRACKING_DATA,
            modal: false,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .build();

      await httpService.executeRequest(request);
    },
    async addCustomizedDelivery({ commit, dispatch }, payload) {
      const request = rttBaseParams(
        HttpRequestBuilder.put(CUSTOMIZE_DELIVERY_PATH),
        payload
      )
        .body(payload.body)
        .onInit(() => {
          commit('updateMessage', {});
          dispatch('startProcess', PROCESS_ADD_CUSTOMIZED_DELIVERY);
        })
        .onSuccess((request, response) => {
          dispatch('stopProcess', PROCESS_ADD_CUSTOMIZED_DELIVERY);
          commit('updateCustomizedDelivery', response.getBody());
        })
        .onError((request, response) => {
          dispatch('stopProcess', PROCESS_ADD_CUSTOMIZED_DELIVERY);
          commit('updateFailure', {
            PROCESS_ADD_CUSTOMIZED_DELIVERY,
            modal: true,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .onFailure((request, response) => {
          dispatch('stopProcess', PROCESS_ADD_CUSTOMIZED_DELIVERY);
          commit('updateFailure', {
            PROCESS_ADD_CUSTOMIZED_DELIVERY,
            modal: true,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .build();

      await httpService.executeRequest(request);
    },
    async addRating({ commit, dispatch }, payload) {
      const request = rttBaseParams(
        HttpRequestBuilder.put(ADD_RATING_PATH),
        payload
      )
        .body(payload.body)
        .onInit(() => {
          commit('updateMessage', {});
          dispatch('startProcess', PROCESS_ADD_RATING);
        })
        .onSuccess((request, response) => {
          dispatch('stopProcess', PROCESS_ADD_RATING);
          commit('updateRatingData', response.getBody());
        })
        .build();

      await httpService.executeRequest(request);
    },
    async addNotificationSubscription({ commit, dispatch }, payload) {
      const request = rttBaseParams(
        HttpRequestBuilder.put(NOTIFICATION_SUBSCRIPTION_PATH),
        payload
      )
        .body(payload.body)
        .onInit(() => {
          commit('updateMessage', {});
          dispatch('startProcess', PROCESS_ADD_NOTIFICATION_SUBSCRIPTION);
        })
        .onSuccess(() => {
          dispatch('stopProcess', PROCESS_ADD_NOTIFICATION_SUBSCRIPTION);
          commit('updateNotificationSubscription', payload);
        })
        .onError((request, response) => {
          dispatch('stopProcess', PROCESS_ADD_NOTIFICATION_SUBSCRIPTION);
          commit('updateFailure', {
            PROCESS_ADD_NOTIFICATION_SUBSCRIPTION,
            modal: true,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .onNotFound((request, response) => {
          dispatch('stopProcess', PROCESS_ADD_NOTIFICATION_SUBSCRIPTION);
          commit('updateFailure', {
            PROCESS_ADD_NOTIFICATION_SUBSCRIPTION,
            modal: false,
            msg: response.getBody(),
            type: ACTION_TYPE_ERROR
          });
        })
        .build();

      await httpService.executeRequest(request);
    },
    async addPushNotification({ commit, dispatch }, payload) {
      commit('updateMessage', {});
      dispatch('startProcess', PROCESS_ADD_NOTIFICATION_SUBSCRIPTION);

      const [error, responseData] = await convertJsonFetchPromise(
        subscribe(payload)
      );

      dispatch('stopProcess', PROCESS_ADD_NOTIFICATION_SUBSCRIPTION);
      if (error) {
        commit('updateFailure', {
          PROCESS_ADD_NOTIFICATION_SUBSCRIPTION,
          modal: error.response?.status !== '404',
          msg: error.message,
          type: ACTION_TYPE_ERROR
        });
        throw error;
      }

      commit('updatePushNotification', responseData);
    },
    async sendTimeFrameFailure({ commit, dispatch }, payload) {
      const request = rttBaseParams(
        HttpRequestBuilder.put(TIME_FRAME_FAILURE_PATH),
        payload
      )
        .body(payload.body)
        .build();

      await httpService.executeRequest(request);
    },
    setMessage({ commit }, message) {
      commit('updateMessage', message);
    },
    setRouteParams({ commit }, payload) {
      commit('updateRouteParams', payload);
    },
    setGdprConsent({ commit }, payload) {
      commit('updateGdprConsent', payload);
    },
    toggleNPSConsent({ commit }) {
      commit('toggleNPSConsent');
    },
    setIsBelowMobileBreakpoint({ commit }, payload) {
      commit('updateIsBelowMobileBreakpoint', payload);
    },
    setIsBelowTabletBreakpoint({ commit }, payload) {
      commit('updateIsBelowTabletBreakpoint', payload);
    },
    setThemeId({ commit }, themeId) {
      commit('updateThemeId', themeId);
    },
    updateCustomizedDeliveryPeriod({ commit }, payload) {
      commit('updateCustomizedDeliveryPeriodMutation', payload);
    },
    readCustomizedDeliveryPeriodState({ commit }, payload) {
      commit('readCustomizedDeliveryPeriodMutation', payload);
    },
    startProcess({ commit }, scope) {
      commit('incrementProcesses', scope);
    },
    stopProcess({ commit }, scope) {
      commit('decrementProcesses', scope);
    },
    async triggerTrackingUrl({ commit, dispatch }, payload) {
      const request = HttpRequestBuilder.get(payload.url)
        .onInit(() => {
          commit('updateMessage', {});
          dispatch('startProcess', PROCESS_LOAD_TRIGGER_TRACKING_URL);
        })
        .onSuccess(() => {
          dispatch('stopProcess', PROCESS_LOAD_TRIGGER_TRACKING_URL);
        })
        .build();

      await httpService.executeRequest(request);
    }
  };
}
