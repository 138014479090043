import country from '@/config/countries.js';

const tenant = (id, country, name, config) => ({
  ...{ id, name, country },
  ...config
});

export default Object.fromEntries(
  [
    tenant('bmdemo-global', country.US, 'Bettermile Demo Global'),
    tenant('bmdemo-uk', country.UK, 'Bettermile Demo UK'),
    tenant('bmdemo-us', country.US, 'Bettermile Demo US'),
    tenant('gls-at', country.AT, 'GLS Austria'),
    tenant('gls-be', country.BE, 'GLS Belgique', {
      stopsSteps: [5, 10, 20, 30, 50, 70, 90],
      disableStopsComponent: true
    }),
    tenant('gls-ca', country.CA, 'GLS Canada'),
    tenant('gls-cz', country.CZ, 'GLS Czech Republic'),
    tenant('gls-de', country.DE, 'GLS Germany'),
    tenant('gls-es', country.ES, 'GLS Spain'),
    tenant('gls-fr', country.FR, 'GLS France'),
    tenant('gls-hr', country.HR, 'GLS Croatia'),
    tenant('gls-hu', country.HU, 'GLS Hungary', {
      resetGdpr: true,
      disableToAddress: true,
      stopsSteps: [5, 10, 20, 30, 50, 70, 90]
    }),
    tenant('gls-ie', country.IE, 'GLS Ireland', { postCodeTextInput: true }),
    tenant('gls-it', country.IT, 'GLS Italia', {
      disableImprintLink: true,
      disableDepositPermission: true,
      disableDeliverToNeighbour: true,
      disableFromAddress: true,
      disableStopsComponent: true
    }),
    tenant('gls-nl', country.NL, 'GLS Netherlands', {
      disable5StarRating: true,
      disableContactLink: true,
      disableImprintLink: true,
      postCodeTextInput: true
    }),
    tenant('gls-pl', country.PL, 'GLS Poland'),
    tenant('gls-ro', country.RO, 'GLS Romania'),
    tenant('gls-rs', country.RS, 'GLS Serbia'),
    tenant('gls-si', country.SI, 'GLS Slovenia'),
    tenant('gls-sk', country.SK, 'GLS Slovakia', {
      stopsSteps: [5, 10, 20, 30, 50, 70, 90]
    }),
    tenant('gls-us', country.US, 'GLS US', {
      disableTrackingId: true,
      disableImprintLink: true
    }),
    tenant('royalmail-uk', country.UK, 'Royal Mail', {
      stopsSteps: [5, 10, 20, 30, 50, 70, 90],
      postCodeTextInput: true
    }),
    tenant('royalmail-uk-test', country.UK, 'Royal Mail (QA)', {
      stopsSteps: [5, 10, 20, 30, 50, 70, 90],
      postCodeTextInput: true
    })
  ].map(tenant => [tenant.id, tenant])
);
